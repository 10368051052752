const datePickerSelector = $('.datepicker');
const timePickerSelector = $('.timepicker');

function round(date, duration, method) {
    // +variable returns the numeric representation of the object -> cast as a number
    return moment(Math[method]((+date) / (+duration)) * (+duration));
}

datePickerSelector.datetimepicker({
    format: 'DD-MM-YYYY',
    minDate: moment().add(0, "days"),
    allowInputToggle: true,
    focusOnShow: false,
    ignoreReadonly: true,
    keepOpen: false
});

datePickerSelector.on('changeDate', function (e) {
    setTimeout(function () {
        $('.datepicker.dropdown-menu.open').remove()
    }, 100)

    var date = moment(e.date)

    var tomorrow = moment().add(1, 'days')

    var nowTime = round(tomorrow, moment.duration(15, "minutes"), "ceil")


    if (date.format('DD-MM-YYYY') === tomorrow.format('DD-MM-YYYY')) {
        $(timePickerSelector).timepicker('option', {'minTime': nowTime.format('hh:mma')});
        $(timePickerSelector).timepicker('setTime', nowTime.format('hh:mma'));
    } else {
        $(timePickerSelector).timepicker('option', {'minTime': null});
        $(timePickerSelector).timepicker('setTime', null);
    }

    try {
        $('.datepicker').datepicker('hide')
    } catch (e) {

    }
});

datePickerSelector.each(function (index, item) {
    const defaultDate = $(item).data('pb-date');
    if (defaultDate !== undefined) {
        $(item).data("DateTimePicker").date(defaultDate)
    }
});

timePickerSelector.timepicker({
    'step': 15,
    'timeFormat': 'G:i',
    'disableTextInput': true,
    'disableTouchKeyboard': true,
    'minTime': $(timePickerSelector).data('min'),
    'maxTime': $(timePickerSelector).data('max')
});


$(".input_visible_header").focus(function () {
    $("#more-info").show('slow')
});

$("button.close-more").focus(function (e) {
    e.preventDefault();
    $("#more-info").hide('slow')
});

function setTime() {
    const time = $('#time-order').val();

    $('input.time-order1').val(time);
}

function cleanLocalStorage() {
    window.localStorage.removeItem("TeAB_it.processed");
    window.localStorage.removeItem("TeAB_it.step1");
    window.localStorage.removeItem("TeAB_it.step2");
    window.localStorage.removeItem("TeAB_it.step3");
    window.localStorage.removeItem("TeAB_it.step4");
    window.localStorage.removeItem("TeAB_it.toProceed");

    window.localStorage.removeItem("TeAB_en.processed");
    window.localStorage.removeItem("TeAB_en.step1");
    window.localStorage.removeItem("TeAB_en.step2");
    window.localStorage.removeItem("TeAB_en.step3");
    window.localStorage.removeItem("TeAB_en.step4");
    window.localStorage.removeItem("TeAB_en.toProceed");
}

if ($('.car.active')[0]) {
    $('.car.active').click(function () {
        $(this).addClass('selectable');
    })
}